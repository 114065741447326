import HttpUtil from '@/utils/HttpUtil'

export default {
  //获取左侧菜单
  getMenuList(params) {
    var url = process.env.VUE_APP_SERVER_CODE+"admin/menu/query/list"
    return HttpUtil.post(url, params);
  },

  // 保存
  add(params) {
    let url = process.env.VUE_APP_SERVER_CODE+"admin/menu/create";
    return HttpUtil.post(url, params);
  },

  // 保存
  update(params) {
    let url = process.env.VUE_APP_SERVER_CODE+"admin/menu/update";
    return HttpUtil.post(url, params);
  },

  // 删除
  delete(params) {
    let url = process.env.VUE_APP_SERVER_CODE+"admin/menu/delete";
    return HttpUtil.get(url, params);
  },

  // 菜单详情
  detail(params) {
    let url = "/am/menu/detail";
    return HttpUtil.post(url, params);
  },
   // 菜单类型
   getMenuTypes() {
    let url = process.env.VUE_APP_SERVER_CODE+"/admin/menu/menuTypes";
    return HttpUtil.get(url);
  }

}
