<template>
  <div style="height: 100%">
    <el-card>
      <el-form v-model="searchForm" inline label-suffix="：" @submit.native.prevent>
        <el-form-item :label="$t('lang_name_of_the_menu')" class="mgb-0">
          <el-input v-model="searchForm.name" @keyup.enter.native="handleSearch" clearable></el-input>
        </el-form-item>
        <el-form-item class="mgb-0">
          <el-button type="primary" icon="el-icon-search" @click="handleSearch"></el-button>
          <el-button type="success" icon="el-icon-refresh" @click="resetSearch"></el-button>
          <el-button type="primary" @click="openMenuForm">{{ $t('lang_new') }}</el-button>
          <el-button :disabled="menuIds.length == 0" type="info" @click="deleteList">{{ $t('lang_delete') }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card class="hg-100-percent" style="height: calc(100% - 97px); margin-top: 10px">
      <el-table
        ref="tableRef"
        border
        :data="menuTreeData"
        height="calc(100% - 100px)"
        v-loading="tableLoading"
        row-key="id"
        default-expand-all
        @select-all="handleSelectAll"
        @select="handleSelect"
        :tree-props="{ children: 'children' }"
      >
        <el-table-column align="center" type="selection" width="55"></el-table-column>
        <el-table-column :label="$t('lang_serial_number')" align="center" type="index" width="150"></el-table-column>
        <el-table-column :label="$t('lang_name_of_the_menu')" align="center" prop="name" show-overflow-tooltip>
          <template v-slot="{ row }">
            {{ $t(row.name) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang_menu_code')" align="center" prop="code" show-overflow-tooltip></el-table-column>
        <el-table-column :label="$t('lang_menu_type')" align="center" prop="menuTypeName" show-overflow-tooltip>
          <template v-slot="{ row }">
            {{ $t(row.menuTypeName) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang_sort_menu')" align="center" prop="sort" show-overflow-tooltip></el-table-column>
        <el-table-column :label="$t('lang_operation')" align="center" width="200">
          <template v-slot="scope">
            <el-button type="text" @click="openMenuForm(scope.row)">{{ $t('lang_edit') }}</el-button>
            <el-button type="text" style="color: red" @click="deleteMenu(scope.row)">{{ $t('lang_delete') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <menu-form :visible.sync="isShowMenuForm" :curMenu.sync="curMenu" :menuTypes="menuTypes" @close="closeMenuForm"></menu-form>
  </div>
</template>

<script>
import MenuApi from '@/api/system/MenuApi';

export default {
  name: 'MenuList',
  components: {
    MenuForm: () => import('./menu-form')
  },
  watch: {},
  data() {
    return {
      tableLoading: false, //表格loading动画
      menuTreeData: [],
      isSelectAll: false,
      searchForm: {
        name: '',
        tenantId: '-1',
        menuType: '1' //menuType: "1" 管理类菜单
      },
      isShowMenuForm: false,
      menuId: '',
      curMenu: {},
      menuIds: [],
      menuTypes: []
    };
  },
  created() {
    Promise.resolve(this.getMenuTypes()).then(() => {
      this.getData();
    });
  },
  methods: {
    //获取表格数据源
    getData() {
      this.tableLoading = true;
      MenuApi.getMenuList(this.searchForm).then(res => {
        if (res.code === '1') {
          this.menuTreeData = res.data;
          this.extendMenuType(this.menuTreeData);
          this.tableLoading = false;
        }
      });
    },
    async getMenuTypes() {
      let res = await MenuApi.getMenuTypes();
      if (res.code === '1') {
        this.menuTypes = res.data;
      }
    },
    extendMenuType(menuData) {
      for (let menu of menuData) {
        for (let type of this.menuTypes) {
          if (type.code === menu.menuType) {
            Object.assign(menu, { menuTypeName: type.name });
          }
          if (menu.children != undefined && menu.children.length > 0) {
            this.extendMenuType(menu.children);
          }
        }
      }
    },
    // 搜索
    handleSearch() {
      this.getData();
    },

    //清空搜索
    resetSearch() {
      this.searchForm.name = '';
      this.getData();
    },

    // 处理全选
    handleSelectAll(selection) {
      this.isSelectAll = !this.isSelectAll;
      if (this.isSelectAll) {
        this.toggleSelection(selection, true);
      } else {
        this.toggleSelection(this.menuTreeData, false);
      }
    },

    // 处理选中行
    handleSelect(selection, row) {
      if (selection.indexOf(row) > -1) {
        this.toggleSelection([row], true);
      } else {
        this.toggleSelection([row], false);
      }
    },

    toggleSelection(rows, flag) {
      if (rows) {
        rows.forEach(item => {
          this.$refs.tableRef.toggleRowSelection(item, flag);
          if (item.children) {
            this.toggleSelection(item.children, flag);
          }
        });
      }
    },

    deleteMenu(row) {
      this.$confirm(this.$t('lang_whether_or_not_to_delete'), this.$t('lang_tips'), {
        confirmButtonText: this.$t('lang_determine_'),
        cancelButtonText: this.$t('lang_cancel_'),
        type: 'warning'
      }).then(() => {
        MenuApi.delete({
          menuId: row.id
        }).then(res => {
          this.$message.success(res.message);
          this.getData();
        });
      });
    },

    deleteList() {
      this.$confirm(this.$t('lang_whether_or_not_to_delete'), this.$t('lang_tips'), {
        confirmButtonText: this.$t('lang_determine_'),
        cancelButtonText: this.$t('lang_cancel_'),
        type: 'warning'
      }).then(() => {});
    },

    openMenuForm(row) {
      if (row) {
        this.curMenu = Object.assign({}, row);
      } else {
        this.curMenu = {};
      }
      this.curMenu.menuType = this.menuTypes[0].code;

      this.isShowMenuForm = true;
    },

    closeMenuForm() {
      this.menuId = '';
      this.getData();
      this.isShowMenuForm = false;
    }
  }
};
</script>

<style scoped lang="scss"></style>
